import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://d5460f0ff3c3f6c33b89cee9a1138767@o362683.ingest.us.sentry.io/4507463617282048",
    // integrations: [
    //     Sentry.browserTracingIntegration(),
    //     Sentry.replayIntegration(),
    // ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/quantor\.pl/, /^https:\/\/stage\.quantor\.pl/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const $ = require("jquery");
window.$ = $;

const moment = require("moment");
const popper = require("popper.js");

require('../../lib/v3/bootstrap/4.1.3/bootstrap.min.js');
require('../../lib/v3/jquery.keyframes/jquery.keyframes.min.js');

import XHR from '../../js/app/xhr/xhr.js';
window.XHR = XHR;



