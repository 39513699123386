export default class XHR {
    
    static get(arg) {
        var xhr = new XMLHttpRequest();
        
        var url = arg.url;
        url = arg.data ? url + this._formatParams(arg.data) : url;

        xhr.open('GET', url);
        xhr.onload = function(e) {
            if(xhr.status === 200) {
                arg.success(xhr.responseText, xhr.status);
            } 
            else if(arg.failure) {
            	arg.failure(xhr.responseText, xhr.status);
            }
        };
        xhr.onerror = function(e) {
            arg.error(xhr.statusText);
        };
        
        if(arg.timeout) {
        	xhr.timeout = arg.timeout;
        	xhr.ontimeout = arg.ontimeout;
        }
        
        xhr.send();
    }
    
    static post(arg) {
        var xhr = new XMLHttpRequest();
        
        var url = arg.url;
        
        url = arg.params ? url + this._formatParams(arg.params) : url;
        
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Authorization', 'Basic ZGV2OnF1YW50b3J0NGpuMw==');
        
        if(!arg.form) {
        	xhr.setRequestHeader('Content-Type','application/x-www-form-urlencoded;charset=utf-8');
        }
        
        xhr.setRequestHeader('Accept', 'application/json, text/plain, */*');
        
        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status <= 299) {
                arg.success(xhr.responseText, xhr.status);
            }
            else if(arg.failure) {
            	arg.failure(xhr.responseText, xhr.status);
            }
        };
        
        if(arg.timeout) {
        	xhr.timeout = arg.timeout;
        	xhr.ontimeout = arg.ontimeout;
        }
       
        if(arg.form) {
        	xhr.send(arg.form);
        }
        else if(arg.data) {
            xhr.send(JSON.stringify(arg.data));
        } else {
            xhr.send();
        }
        
    }
    
    static _formatParams(params) {
        return "?" + Object
                  .keys(params)
                  .map(function(key){
                      return key+"="+encodeURIComponent(params[key])
                  })
                  .join("&");
    } 
    
}